@import 'colors';

/*.modal-body {
    max-height: calc(50vh - 110px);
    overflow-y: auto;
}*/

body {
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-overflow-style: none;
  }
  
  .body-child {
    -ms-overflow-style: auto;
  }
  
  .ajs-message.ajs-custom { 
    color: $purple; 
    background-color: white;
    border-color: $purple; 
  }
  
  .alert-table-header {
    padding: 2px 15px;
  }
  
  .alert-table-cell {
    padding: 2px 15px;
  }
  
  #alert-table-body {
    overflow-y: scroll;
  }
  
  .modal-90w {
      width: 90%;
      max-width: none!important;
  }
  
  .custom-modal {
    /*width: fit-content;*/
    width: 70vw;
  }
  
  .custom-udo-modal {
    width: 80vw;
  }
  
  .custom-table-overflow {
    overflow-y: auto;
  }
  
  .custom-details-comments-modal {
    width: 80vw;
  }
  
  .custom-analysis-button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }
  
  .panel.noborder {
      border: none;
      box-shadow: none;
  }
  .panel.noborder > .panel-body {
      border: 3px solid #dddddd;
      border-radius: 0;
  }
  
  .project-status-select {
    font-size: 11px;
  }
  
  .udo-tooltip > .tooltip-inner {
    background-color: rgba(255,255,255, 1) !important;
    color: #000;
    border: 1px solid black;
    text-align: left;
  }
  
  .udo-tooltip.tooltip.in {
    opacity: 1 !important;
  }
  
  .udo-tooltip > .tooltip-arrow {
    color: $purple !important;
  }
  
   .custom-project-list-icon:hover {
    color: $purple;
    transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)';
  }
  
  .details-comments-tooltip > .tooltip-inner {
    background-color: rgba(255,255,255, 1) !important;
    max-width: 300px;
    color: #000;
    border: 1px solid black;
    text-align: left;
    word-break: break-all;
    overflow: hidden;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  
  #pop-warning {
    animation: pop 0.3s linear 1;
  }
  
  @-webkit-keyframes pop {
    50%  {
      transform: scale(1.2);
    }
  }
  @-moz-keyframes pop {
    50%  {
      transform: scale(1.2);
    }
  }
  @-o-keyframes pop {
    50%  {
      transform: scale(1.2);
    }
  }
  @keyframes pop {
    50%  {
      transform: scale(1.2);
    }
  }
  
  #settings-box {
    animation: createBox .10s;
    height: 600px;
    width: 800px;
  }
  
  @keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  
  
  
  .details-comments-tooltip.tooltip.in {
    opacity: 1 !important;
  }
  
  .details-comments-tooltip > .tooltip-arrow {
    color: $purple !important;
  }
  
  /* .editable-name-field:hover {
    border: 1px solid red;
  } */
  
  /* .editable-text-icon:hover {
    color: white;
    background-color: $purple;
  } */
  
  .custom-analysis-select {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  
  .modal-container {
    position: relative;
  }
  .modal-container .modal, .modal-container .modal-backdrop {
    position: absolute;
  }
  
  td .form-group {
    margin-bottom: 0px;
  }
  
  .bold {
      font-weight: bold;
  }
  
  .green {
      background-color: lightgreen;
  }
  
  .excludedValue {
      text-decoration: line-through;
  }
  
  .availableValue {
      font-style: italic;
  }
  .overForDragNDrop {
    border: '2px dashed #000';
  }
  .valueSample {
      margin-top: 2px;
      margin-bottom: 2px;
      font-weight: normal;
      background-color: white;
      text-align: left !important;
  }
  
  
  #databaseInfoModal .modal-dialog {
    width: 60%;
  }
  
  .rangeslider-horizontal .rangeslider__fill {
    background-color: $purple;
  }
  
  .slider-horizontal .rangeslider-horizontal {
      height: 8px;
  }
  
  .slider-horizontal .rangeslider__handle {
      width: 0px;
      height: 0px;
      border-radius: 0;
  }
  
  .rangeslider-horizontal .rangeslider__handle:after {
    top: -8px;
    left: -8px;
    background-color: white;
  }
  
  .rangeslider__labels {
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding: 10px 0px;
      pointer-events: none;
  }
  
  .rangeslider__labels .rangeslider__label-item {
      position: inherit; 
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      top: 10px;
      transform: none;
  }
    
  .rangeslider__labels .rangeslider__label-item {
    left: 0px !important;
  }
  
  .segmentPreviewModalContainer {
      width: auto;
      margin-left: 8vw;
      margin-right: 8vw;
      max-width: none;
  }
  
  #todBackupModal .modal-dialog {
    width: 60%;
  }
  
  .edit-modal {
    width: 90%;
    max-width: none;
  }
  
  .review-confirmation-modal {
      font-size: smaller;
  }
  
  .download-confirmation-modal {
    width: 50%;
  }
  
  .wait-modal {
    width: 90%;
    max-width: none;
  }
  
  .wait-modal .progress-bar {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }​
  
  .add-icon {
    color: $dark-grey;
    border-radius: 5px;
    margin-right: 5vw;
    cursor: pointer;
    font-size: 4.5vmin;
  }
  .add-icon:hover, .dialog-button:hover {
    background-color: $purple;  
    color: white;
    transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)';
  }
  
  .dialog-button {
      background-color: $blue;
      color: white;
  }
  
  
  
  
  
  
  
  .header {
  
    display: inline-block;
  
    margin: 0;
  
    padding: 0;
  
    background-color: #E0E0E0;
  
    width:100%;
  
  }
  
  
  
  .task-header {
  
    display: inline-block;
  
    background-color: skyblue;
  
    width:100%;
  
  }
  
  
  
  .droppable {
      position: absolute;
      width: 150px;
      height: 100vh;
      right: 0;
      top: 10;
      background-color: #9E9E9E;
      border-left: 1px dotted;
  }
  
  .draggable {
      text-align: center;
      background-color: rgb(104, 104, 101);
      font-weight: bold;
      margin: 3px;
      border: solid black 1px;
  }
  
  .delete-button {
      cursor: pointer;
  }
  
  .taskBtn {
      margin-left: 25px;
  }
  
  .ttc[disabled] {
     background-color: #eee !important;
  }
  
  .over {
      background-color:$light-purple;
  }
  
  .serial-number {
      display: none;
  }
  
  .x-form {
      padding: 10px;
  }
  
  .x-form label {
      color: $purple;
      font-weight: bold;
      text-align: right;
  }
  
  .x-form .col>input {
      width: 100% !important;
  }
  
  
  .wdd-trash { background-color: red;}
  
  .btn-arius {
      color: $dark-grey;
      border-radius: 5px;
      cursor: pointer;
      border: solid 1px;
  }
  
  .btn-arius:hover {
      background-color: $purple;
      color: white;
      transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)';
  }
  
  .nav-link-arius, .navbar-nav .nav-link-arius.nav-link, .analysis-home-link a {
      color: $blue;
      font-weight: bold;
      font-size: smaller;
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
  }
  
  .nav-tabs-arius, .nav-side-tabs-arius { 
      border: none;
      padding-top: 16px;
      border-bottom: 1px solid #dee2e6;
      margin-bottom: -9px;
  }
  
  .nav-side-tabs-arius a.nav-link {
      font-size: 16px;
      font-weight: normal;
      border-top: 1px solid #dee2e6;
  }
  .nav-side-tabs-arius a.nav-link.active {
      background-color: #dee2e6;
  }
  
  .list-container-arius {
      width: 100vw;
      padding: 0 5vw;
      max-height: calc(100vh - 52px);
  }
  
  .list-header-arius {
      border-bottom: 2px solid #BDBDBD;
      margin: initial;
      height: 75px !important; 
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px 10px 20px;
  }
  .list-header-arius > h4 { margin: 0; }
  
  #analysisToolbar ~ .list-container-arius, .sidebar + .list-container-arius { 
      /* lists in the analysis page must allow space for left hand menu... */
      width: calc(100vw - 170px);
      padding: 0;
  }
  
  /* tableRow
  fontSizes
  tableCellContent
  cellPositioning */
  
  
  .table-row {
      display: table;
      width: 90vw;
      table-layout: fixed;
  }
  
  th.table-cell-content,  td.table-cell-content, div.editable-text-field {
      height: initial;
      font-size: 11px;
      padding-top: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      padding-left: 8px;
  }
  
  th.table-cell-content {
      font-size: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
  }
  
  th.cell-positioning, td.cell-positioning {
      margin: 0;
      vertical-align: middle;
  }
  
  .table-responsive-arius {
      padding-top: 10px;
      padding-left: 15px;
      padding-right: 15px;
      min-width: 65vw;
      margin: auto;
  }
  
  .table-responsive-arius tbody.scrolling {
      display: block;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
  }
    
  .table-responsive-arius thead, .table-responsive-arius tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
  }
  
  .pointer { cursor: pointer; }
  
  .conditional-link { padding-top: 10px;}
  
  .bg-whiteSmoke {
    background-color: #f5f5f5;
  }
  
  .bg-info-old {
    background-color: #d9edf7
  }
  
  .bg-primary-old {
    background-color: $blue
  }
  
  .bg-success-old {
    background-color: #dff0d8;
  }
  
  .text-primary-old {
    color: $blue
  }
  
  .confirmModal {
    max-width: 600px;
  }
  
  .selected-row { background-color: $light-grey !important; }
  
  .clauseObjectEditorModalContainer {
    width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
    max-width: none;
  }
  
  .form-group label:not([type='checkbox']) { font-weight: 800; }
  
  .preQueryPreview {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  input[type='radio']:disabled {
    cursor: not-allowed;
  }
  
  .menu-icon { 
      cursor: pointer;
      font-size: 20px !important;
      padding: 5px;
      color: $dark-grey !important;  /*  TODO use sass moving forward */
  }
  .menu-icon:hover {
        color: $purple !important;
        transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
  }

    .arius-stack {  // mimics the fa-stack class
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        font-size: 20px;
        margin-left: 4px;
        margin-right: 4px;
        padding: 10px;
        vertical-align: middle;
    }

    .arius-stack-1x { // mimics the fa-stack-1x class
        position: absolute;
        left: 0px;
        top: -4px;
        width: 100%;
    }

    .arius-stack-1x.wait { color: $blue; }

  .cancel-icon {
    color: red !important;
  }
  .cancel-icon-disabled {
    color: #dddddd;
    pointer-events: none;
  }
  
  .add-icon { 
      border-radius: 5px;
      margin-right: 5vw;
      font-size: 4.5vmin;
  }
  .add-icon:hover { color: white !important;}
  
  
  .menu-icon-disabled { cursor: not-allowed; }
  
  .tool-tip-fix {
       pointer-events: none; /*aims to fix blinking tooltip overlay in latest bootstrap  */
  }
  
  /* This is an attempt to allow an unstriped grid nested in a striped grid. */
  .table-unstriped > tbody > tr > td, .table-unstriped > thead > tr > th { background-color: white;}
  
  .calculated-modal {
    max-width: 600px;
  }
  
  .navbar-brand img {  height: 25px; padding-top: 0; vertical-align: 0 }
  .navbar-brand img:hover { cursor: pointer }
  
  .download-confirmation-modal {
    max-width: none;
    width:60%;
  }
  
  /* Syncfusion related ============================= */
  .e-grid div.e-headercelldiv { font-size:12px; padding-left: 3px !important;} /*grid header label */
  .e-grid .e-rowcell {font-size:11px;padding: 8px;}       /*grid data row  */
  .e-grid .e-headercell { padding: 8px 8px 5px 8px}       /*grid header  */
  .e-grid td.e-rowcell.e-templatecell {padding: 2px 2px 2px 8px;} /*grid cell that uses a template */
  .e-grid .e-sortfilterdiv {margin-right:-12px;} /* a little nudge on the sorting icon */
  .e-grid .e-altrow { background-color: #fafafa;}
  /* ================================================ */
  
  .create-script-box{
      height: calc(100vh - 245px);
      width: 100%;
      padding: 1em;
      max-width: 100%;
      display: flex;
      overflow-y: auto;
      flex-flow: column nowrap;
  }
  
  .create-script-box .row {
    margin: 0;
  }
  /* TODO WDD remove this bandaid to wrangle the fontsizes in create script page (needs a rewrite at some point) ============================= */
  .create-script-box label, .create-script-box .form-control, .create-script-box .available-commands {
      font-size: 14px;
  }
  /* ================================================ */
  
  .projectInfoModal{
    width: auto;
      margin-left: 8vw;
      margin-right: 8vw;
      max-width: none;
  }
  
  .no-caret .dropdown-toggle::after {
      border-top: 0;  /* hide the caret on a navdropdown */
  }
  
  .edit-text:hover { cursor: pointer;}/* this section handles the project name edit */
  
  .task-grid .e-headercontent {height: 0}
  .task-grid td.e-rowcell.e-templatecell { color:$blue}
  .task-grid-detail .e-headercontent {height: auto}
  .task-grid-detail td.e-rowcell.e-templatecell { color: black;}
  
  .levelsWarningMessage { font-weight: bold; margin-bottom: 10px;}
  
  
  .tile-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10vmin;
    overflow-y: auto;
    height: 85vh;
  }
  .tile-container .arius-logo { width: 20%; }
  
  @media screen and (min-width:0\0) {
    .tile-container .arius-logo { width: 180px; }/* IE FIX TO MAKE HOMEPAGE RENDER REASONABLY */
  }
  
  .tile-container h2{ color: $purple; text-align: center; }
  
  .tile-container .tile-row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }
  
  .tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20vw;
    min-width: 200px;
    height: 180px;
    background-color: $blue;
    margin: 10px;
    color: white;
    border-radius: 3px;
    cursor: pointer;font-size: 17px; 
    text-align: center;
  }
  
  .tile:hover {
    background-color: $purple;
    color: white;
    text-decoration: none;
    transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
  }
  
  .tile > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .tile img { width: 80px;}
  .tile i { font-size: 80px;}
  
  .input-loading { color: $purple; font-weight: bold;}

//   QUERY BUILDING RELATED
.sequence {
    color: $sql-dark-grey;
    // text-align: center; 
    // border-radius: 5px;
    // border-left: solid black 3px;
    // border-right: solid black 3px;
}
.subject { 
    color: $black;
    margin: 3px;
    border-radius: 5px
}
.remove:after { 
    content: '\00A0'
  }
.remove:hover:after{
    content: '\00d7'
}

.object-expand, .object-collapse { white-space: nowrap;}
.object-expand:after { content: '\00A0'}
.object-expand:hover:after{content: '\002B\00A0'} // plus sign
.object-collapse:after { content: '\00A0'}
.object-collapse:hover:after{content: '\002D\00A0'} // minus sign


.empty {
    text-align: center;
    border: dashed 1px black;
    border-radius: 5px;
    padding: 0px 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 120px;
}

.verb { 
    text-transform: uppercase; 
    white-space: nowrap;
    color: #A9A9A9;
    margin: 3px;
    margin-right: 10px;
    text-align: center;
    border-radius: 5px
}

.clause-object {
    margin-left: 0px;
    color: $sql-dark-grey;
    max-width: calc(100vw - 300px);
}

.clause-object-item {
    color: $red;
}

.clause-object-group {
    color: $blue;
}

.operator {
    color: $sql-dark-grey;
}

.subject:hover, 
.clause-object:hover, 
.operator:hover, 
.verb:hover, 
.sequence:hover, 
.clause:hover,
.clause-object-group:hover,
.clause-object-item:hover {cursor: pointer;color:$purple;}


.main-container {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
}

.pbi-report-link > .e-acrdn-header {
    padding: 0px !important;
}

.pbi-report-link > .e-acrdn-header:hover {
    text-decoration: none !important;
}

.pbi-report-link .e-acrdn-header-content > div {
    white-space: normal;
    padding-right: 10px;
}

.pbi-report-link .e-acrdn-header-content .nav-link-arius {
    margin-right: 10px !important;
}

.pbi-report-link .e-acrdn-header-content:hover {
    text-decoration: none !important;
}
.pbi-report-link .e-acrdn-header-content .nav-link-arius:hover {
    color: $purple !important;
}


